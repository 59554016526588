import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"

const AboutPage = () => (
  <Layout>
    <Head title="About" />
    <h1>About us</h1>
    <h3>Mi smo Vam super cool.</h3>
  </Layout>
)

export default AboutPage
